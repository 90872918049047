import React from "react";
import styled from "styled-components";
import Colors from "../../styles/colors";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import Routes from "../../utils/routes";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  & > h1 {
    font-size: 100px;
    font-weight: bold;
    color: ${Colors.MediumGreen};
    margin: 0;
  }

  & > h2 {
    font-size: 20px;
    color: ${Colors.DarkBlue};
    margin: 0;
  }
`;

const TextContent = styled.div`
  white-space: pre-line;
`;

const NotFound: React.FC = () => {
  return (
    <Container>
      <h1>404</h1>
      <h2>
        <FormattedMessage
          tagName={TextContent}
          id="PAGE_NOT_FOUND"
          values={{
            home: (...chunks: any[]) => <Link to={Routes.Home}>{chunks}</Link>
          }}
        />
      </h2>
    </Container>
  );
};

export default NotFound;
