import React from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import styled from "styled-components";
import Colors from "../styles/colors";
import { breakPoints } from "../styles/responsive";

const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const Page = styled.article`
  background-color: ${Colors.DarkBlue};
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

const Content = styled.div`
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: ${breakPoints.small}) {
    width: 550px;
  }
  @media (min-width: ${breakPoints.medium}) {
    width: 750px;
  }
  @media (min-width: ${breakPoints.large}) {
    width: 900px;
  }
`;

const MainLayout: React.FC = ({ children }) => {
  return (
    <LayoutContainer>
      <Header />
      <Page>
        <Content>{children}</Content>
      </Page>
      <Footer />
    </LayoutContainer>
  );
};

export default MainLayout;
