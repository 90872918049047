const acceptedLanguages = ["en", "fr", "it"];
const defaultLocale = "fr";

const currentLanguage = navigator.language.slice(0, 2);
export const locale = acceptedLanguages.includes(currentLanguage)
  ? currentLanguage
  : defaultLocale;

export const messages: {
  [key: string]: any;
} = {
  en: require("../locales/en.json"),
  fr: require("../locales/fr.json"),
  it: require("../locales/en.json")
};
