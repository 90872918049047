import React, { useEffect } from "react";
import { BreakpointProvider } from "react-socks";
import styled from "styled-components";
import Colors from "../styles/colors";
import GlobalStyle from "../styles/global";
import User, { Logged } from "../state/user";
import Login from "../pages/Login/Login";
import { getUserInfo } from "../api/user";
import { IntlProvider } from "react-intl";
import { locale, messages } from "../utils/locales";
import MainLayout from "../layouts/MainLayout";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import Routes from "../utils/routes";
import Home from "../pages/Home/Home";
import Announcements from "../pages/Announcements/Annoucements";
import Calendar from "../pages/Calendar/Calendar";
import Events from "../pages/Events/Events";
import Genealogy from "../pages/Genealogy/Genealogy";
import Groups from "../pages/Groups/Groups";
import Members from "../pages/Members/Members";
import Settings from "../pages/Settings/Settings";
import NotFound from "../pages/404/404";

const Container = styled.div`
  background-color: ${Colors.DarkBlue};
  color: ${Colors.White};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const AppContainer: React.FC = ({ children }) => {
  const { setUser } = User.useContainer();
  const { isLoggedIn, setLoggedIn } = Logged.useContainer();

  useEffect(() => {
    async function fetchUser() {
      const { data: user } = await getUserInfo();
      setUser(user);
      setLoggedIn(!!user);
    }

    fetchUser();
  }, [setUser, setLoggedIn]);

  return (
    <Container>
      <IntlProvider locale={locale} messages={messages[locale]}>
        {isLoggedIn && children}
        {!isLoggedIn && <Login />}
      </IntlProvider>
    </Container>
  );
};

const App: React.FC = () => {
  return (
    <BreakpointProvider>
      <GlobalStyle />
      <Logged.Provider>
        <User.Provider>
          <AppContainer>
            <MainLayout>
              <Router>
                <Switch>
                  <Route path={Routes.Home} exact component={Home} />

                  <Route
                    path={Routes.Announcements}
                    component={Announcements}
                  />

                  <Route path={Routes.Calendar} component={Calendar} />

                  <Route path={Routes.Events} component={Events} />

                  <Route path={Routes.Genealogy} component={Genealogy} />

                  <Route path={Routes.Groups} component={Groups} />

                  <Route path={Routes.Members} component={Members} />

                  <Route path={Routes.Settings} component={Settings} />

                  <Route component={NotFound} />
                </Switch>
              </Router>
            </MainLayout>
          </AppContainer>
        </User.Provider>
      </Logged.Provider>
    </BreakpointProvider>
  );
};

export default App;
