interface ISettings {
  api: {
    url: string;
    timeout: number;
  };
}

const settings: ISettings = {
  api: {
    url: "https://dev-api.lonard.org",
    timeout: 10000
  }
};

export default settings;
