import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import Colors from "../../styles/colors";

const Container = styled.footer`
  display: flex;
  background-color: ${Colors.DarkGrey};
  align-items: center;
  justify-content: center;
  height: 80px;
  color: ${Colors.White};
  border-top: 1px solid ${Colors.MediumGrey};
  z-index: 1;
`;

const TextContent = styled.div`
  white-space: pre-line;

  & > .pink {
    color: ${Colors.LovePink};
  }
`;

const Footer: React.FC = () => {
  return (
    <Container>
      <FormattedMessage
        tagName={TextContent}
        id="FOOTER_TEXT"
        values={{
          pink: (...chunks: any[]) => <span className="pink">{chunks}</span>
        }}
      />
    </Container>
  );
};

export default Footer;
