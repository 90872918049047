import React, { useState } from "react";
import styled from "styled-components";
import Colors from "../../styles/colors";

const Field = styled.input`
  padding: 0.5rem;
  border: 1px solid ${Colors.LightGrey};
  flex: 1;
  height: 30px;

  &:focus {
    border-color: ${Colors.MediumGreen};
    box-shadow: 0 0 2px 0 ${Colors.MediumGreen};
  }
`;

const Label = styled.label`
  display: flex;
  align-items: center;

  & > .label,
  & > .icon {
    margin-right: 5px;
  }
`;

export enum InputType {
  Text = "text",
  Password = "password",
  Number = "number"
}

interface IProps {
  type: InputType;
  label?: string;
  icon?: (props: any) => JSX.Element;
  placeholder?: string;
  onChange?: (value: string | number) => void;
  className?: string;
  required?: boolean;
  disabled?: boolean;
}

const Input: React.FC<IProps> = ({
  type,
  label,
  icon: Icon,
  placeholder,
  onChange,
  className,
  required = false,
  disabled = false
}) => {
  const onValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (onChange) onChange(event.target.value);
  };
  const onFieldFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.preventDefault();
    setIconColor(Colors.MediumGreen);
  };
  const onFieldBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    event.preventDefault();
    setIconColor(Colors.LightGrey);
  };

  const [iconColor, setIconColor] = useState(Colors.LightGrey);

  return (
    <Label>
      {label && <span className="label">{label}</span>}
      {Icon && (
        <Icon style={{ color: iconColor }} fontSize="large" className="icon" />
      )}
      <Field
        type={type}
        placeholder={placeholder}
        onChange={onValueChange}
        className={className}
        onFocus={onFieldFocus}
        onBlur={onFieldBlur}
        required={required}
        disabled={disabled}
      />
    </Label>
  );
};

export default Input;
