enum Colors {
  DarkBlue = "#282c34",
  White = "#ffffff",
  Black = "#000000",
  MediumGreen = "#4bad89",
  LightGrey = "#dddddd",
  MediumGrey = "#a4a4a4",
  DarkGrey = "#2B343B",
  GreyBlue = "#1E272E",
  ErrorRed = "#e12c5f",
  LovePink = "#f08f90"
}

export default Colors;
