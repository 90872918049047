import React from "react";
import styled from "styled-components";
import classnames from "classnames";
import Colors from "../../styles/colors";

const StyledButton = styled.button`
  border: none;
  color: ${Colors.White};
  display: flex;
  align-items: center;
  justify-content: center;

  &.primary {
    background-color: ${Colors.DarkBlue};

    &:hover {
      box-shadow: 0 0 3px 0 ${Colors.DarkBlue};
    }
  }
  &.secondary {
    background-color: ${Colors.MediumGreen};
    &:hover {
      box-shadow: 0 0 3px 0 ${Colors.MediumGreen};
    }
  }
  &.transparent {
    background: transparent;
    border: 1px solid ${Colors.White};
    &:hover {
      box-shadow: 0 0 3px 0 ${Colors.White};
    }
  }

  &:hover {
    cursor: pointer;
  }
  &:disabled {
    background-color: ${Colors.White};
    color: ${Colors.LightGrey};
    border: 1px solid ${Colors.LightGrey};
    box-shadow: none;
  }

  &.small {
    font-size: 16px;
    height: 38px;
  }
  &.medium {
    font-size: 18px;
    height: 42px;
  }
  &.large {
    font-size: 20px;
    height: 46px;
  }

  &.uppercase {
    text-transform: uppercase;
  }
  &.rounded {
    border-radius: 4px;
  }
`;

export enum ButtonTheme {
  Primary = "primary",
  Secondary = "secondary",
  Transparent = "transparent"
}

export enum ButtonSize {
  Small = "small",
  Medium = "medium",
  Large = "large"
}

export enum ButtonType {
  Submit = "submit",
  Button = "button",
  Reset = "reset"
}

export enum ButtonTransform {
  None = "none",
  Uppercase = "uppercase"
}

interface IProps {
  theme?: ButtonTheme;
  size?: ButtonSize;
  type?: ButtonType;
  disabled?: boolean;
  className?: string;
  onClick?: () => void;
  transform?: ButtonTransform;
  rounded?: boolean;
}

const Button: React.FC<IProps> = ({
  theme = ButtonTheme.Primary,
  size = ButtonSize.Medium,
  type = ButtonType.Button,
  disabled = false,
  className,
  onClick,
  children,
  transform = ButtonTransform.None,
  rounded
}) => {
  return (
    <StyledButton
      className={classnames(className, {
        [size]: true,
        [theme]: true,
        [transform]: true,
        rounded
      })}
      type={type}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </StyledButton>
  );
};

export default Button;
