import axios, { AxiosResponse } from "axios";
import settings from "../settings";

const axiosService = axios.create({
  baseURL: settings.api.url,
  timeout: settings.api.timeout,
  headers: {
    authorization: localStorage.getItem("token")
      ? `bearer ${localStorage.getItem("token")}`
      : null
  }
});

export const axiosSetToken = (token: string) => {
  axiosService.defaults.headers = {
    ...axiosService.defaults.headers,
    authorization: `bearer ${token}`
  };
};

export const axiosDeleteToken = () => {
  delete axiosService.defaults.headers.authorization;
  localStorage.removeItem("token");
};

axiosService.interceptors.response.use(
  (response: AxiosResponse<{ accessToken?: string }>) => {
    if (response.data.accessToken) {
      localStorage.setItem("token", response.data.accessToken);
      axiosSetToken(response.data.accessToken);
      delete response.data.accessToken;
    }

    return response;
  }
);

export default axiosService;
