import {
  PersonOutlined,
  GroupOutlined,
  AnnouncementOutlined,
  EventNoteOutlined,
  TodayOutlined,
  AccountTreeOutlined
} from "@material-ui/icons";

enum Routes {
  Home = "/",
  Members = "/members",
  Groups = "/groups",
  Announcements = "/announcements",
  Events = "/events",
  Calendar = "/calendar",
  Genealogy = "/trees",
  Settings = "/settings"
}

export default Routes;

export interface MenuItem {
  title: string;
  link: Routes;
  icon?: (props: any) => JSX.Element;
}

export const MenuItems: MenuItem[] = [
  {
    title: "ANNOUNCEMENT_MENU_ITEM",
    link: Routes.Announcements,
    icon: AnnouncementOutlined
  },
  {
    title: "EVENTS_MENU_ITEM",
    link: Routes.Events,
    icon: EventNoteOutlined
  },
  {
    title: "CALENDAR_MENU_ITEM",
    link: Routes.Calendar,
    icon: TodayOutlined
  },
  {
    title: "MEMBERS_MENU_ITEM",
    link: Routes.Members,
    icon: PersonOutlined
  },
  {
    title: "GROUPS_MENU_ITEM",
    link: Routes.Groups,
    icon: GroupOutlined
  },
  {
    title: "GENEALOGY_MENU_ITEM",
    link: Routes.Genealogy,
    icon: AccountTreeOutlined
  }
];
