import React, { useMemo } from "react";
import styled from "styled-components";
import { logout } from "../../api/user";
import User, { Logged } from "../../state/user";
import Button, { ButtonType, ButtonTheme, ButtonSize } from "../forms/Button";
import Colors from "../../styles/colors";
import defaultAvatar from "../../assets/avatar.png";
import Avatar from "./Avatar";
import Menu from "@material-ui/icons/Menu";

const Container = styled.header`
  color: ${Colors.White};
  background-color: ${Colors.DarkGrey};
  height: 80px;
  display: flex;
  align-items: center;
  padding: 0 2rem;
  border-bottom: 1px solid ${Colors.MediumGrey};
  z-index: 1;

  & > .signout {
    margin-left: auto;
  }
`;

const Header: React.FC = () => {
  const { user } = User.useContainer();
  const { setLoggedIn } = Logged.useContainer();

  const avatar = useMemo(() => user?.avatar?.url || defaultAvatar, [user]);
  const username = useMemo(() => user?.username || user?.firstname, [user]);

  const signout = () => {
    logout();
    setLoggedIn(false);
  };

  return (
    <Container>
      <Avatar url={avatar} username={username} />
      <Button
        type={ButtonType.Button}
        theme={ButtonTheme.Transparent}
        onClick={signout}
        size={ButtonSize.Small}
        className="signout"
      >
        <Menu />
      </Button>
    </Container>
  );
};

export default Header;
