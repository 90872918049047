import React, { useState } from "react";
import styled from "styled-components";
import { login, getUserInfo } from "../../api/user";
import User, { Logged } from "../../state/user";
import Input, { InputType } from "../../components/forms/Input";
import { Person, Lock } from "@material-ui/icons";
import Form from "../../components/forms/Form";
import Colors from "../../styles/colors";
import { breakPoints } from "../../styles/responsive";
import Button, {
  ButtonType,
  ButtonTheme,
  ButtonTransform
} from "../../components/forms/Button";
import { Breakpoint } from "react-socks";
import { FormattedMessage, useIntl } from "react-intl";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > h1 {
    color: ${Colors.White};
    text-align: center;
  }
`;

const LoginCard = styled.div`
  display: flex;
  flex-direction: column;
  color: ${Colors.DarkBlue};
  align-items: center;
  background-color: ${Colors.White};
  padding: 2rem;
  box-shadow: 0 0 12px 0 ${Colors.Black};
  width: 400px;
  border-radius: 4px;

  & > .form {
    width: 100%;

    .submitButton {
      margin-top: 20px;
    }
  }

  @media (max-width: ${breakPoints.small}) {
    width: calc(100% - 6rem);
  }
`;

const ForgotPassword = styled.a`
  color: ${Colors.MediumGreen};
  align-self: flex-end;
  margin-bottom: 20px;
`;

const ErrorMessage = styled.p`
  color: ${Colors.ErrorRed};
  margin: 0;
  font-weight: bold;
`;

const Login: React.FC = () => {
  const intl = useIntl();
  const { setUser } = User.useContainer();
  const { setLoggedIn } = Logged.useContainer();

  const [identifer, setIdentifier] = useState();
  const [password, setPassword] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const submitForm = async () => {
    await login({
      username: identifer,
      password
    }).catch(e => setErrorMessage(e.response.data.message));
    const { data: user } = await getUserInfo();

    setUser(user);
    setLoggedIn(!!user);
  };

  return (
    <Container>
      <h1>
        <FormattedMessage id="WELCOME_MESSAGE" />
      </h1>
      <LoginCard>
        <h2>
          <FormattedMessage id="CONNECTION" />
        </h2>
        {errorMessage && (
          <ErrorMessage>
            <FormattedMessage id={errorMessage} />
          </ErrorMessage>
        )}
        <Form onSubmit={submitForm} className="form">
          <Breakpoint xsmall>
            <Input
              type={InputType.Text}
              placeholder={intl.formatMessage({ id: "IDENTIFIER" })}
              onChange={setIdentifier}
              required
            />
            <Input
              type={InputType.Password}
              placeholder={intl.formatMessage({ id: "PASSWORD" })}
              onChange={setPassword}
              required
            />
          </Breakpoint>
          <Breakpoint small up>
            <Input
              icon={Person}
              type={InputType.Text}
              placeholder={intl.formatMessage({ id: "IDENTIFIER" })}
              onChange={setIdentifier}
              required
            />
            <Input
              icon={Lock}
              type={InputType.Password}
              placeholder={intl.formatMessage({ id: "PASSWORD" })}
              onChange={setPassword}
              required
            />
          </Breakpoint>
          <ForgotPassword href="#">
            <FormattedMessage id="FORGOT_PASSWORD" />
          </ForgotPassword>
          <Button
            type={ButtonType.Submit}
            theme={ButtonTheme.Secondary}
            className="submitButton"
            transform={ButtonTransform.Uppercase}
          >
            <FormattedMessage id="SUBMIT" />
          </Button>
        </Form>
      </LoginCard>
    </Container>
  );
};

export default Login;
