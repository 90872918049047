import React from "react";
import styled from "styled-components";
import Colors from "../../styles/colors";
import { FormattedMessage } from "react-intl";

const Container = styled.div`
  display: flex;
  align-items: flex-end;
`;

const Frame = styled.div`
  padding: 0.4rem;
  border: 2px solid ${Colors.White};
`;

const Image = styled.div<{ url: string }>`
  width: 45px;
  height: 45px;
  background-image: ${({ url }) => `url(${url});`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 0 center;
`;

const Username = styled.p`
  margin: 0 0 0 5px;
`;

interface IProps {
  url: string;
  username?: string;
}

const Avatar: React.FC<IProps> = ({ url, username }) => {
  return (
    <Container>
      <Frame>
        <Image url={url} />
      </Frame>
      {username && (
        <Username>
          <FormattedMessage id="WELCOME" />, {username}
        </Username>
      )}
    </Container>
  );
};

export default Avatar;
