import { LoginDTO, TokenDTO } from "../dtos/login.dtos";
import axiosService, { axiosDeleteToken } from "./axios";
import { UserPrivateDTO } from "../dtos/users.dtos";

export const login = (payload: LoginDTO) =>
  axiosService.post<TokenDTO>("/login", payload);

export const getUserInfo = () => axiosService.get<UserPrivateDTO>("/users/me");

export const logout = () => axiosDeleteToken();
