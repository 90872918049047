import { setDefaultBreakpoints, BreakpointType } from "react-socks";

const bps: BreakpointType = {
  xsmall: 0,
  small: 576,
  medium: 768,
  large: 992,
  xlarge: 1200
};

export const tabletWidthRem = 48;
export const desktopWidthRem = 80;
export const layoutWidth = `${bps.small}px`;

export const breakPoints: {
  xsmall: string;
  small: string;
  medium: string;
  large: string;
  xlarge: string;
} = Object.entries(bps).reduce((acc: any, [name, value]) => {
  acc[name] = `${value}px`;
  return acc;
}, {});

setDefaultBreakpoints(
  Object.entries(bps).map(
    ([name, value]: [string, number]): BreakpointType => ({
      [name]: value
    })
  )
);
