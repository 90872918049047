import { UserPrivateDTO } from "../dtos/users.dtos";
import { useState, useCallback } from "react";
import { createContainer } from "unstated-next";

type IUserState = UserPrivateDTO;

function useUser() {
  const [user, setUserState] = useState<IUserState>();

  const setUser = useCallback(
    (newUser: UserPrivateDTO) => setUserState(() => newUser),
    [setUserState]
  );

  return { user, setUser };
}

function useLogin() {
  const [isLoggedIn, setLoggedInState] = useState(false);

  const setLoggedIn = useCallback(
    (logged: boolean) => setLoggedInState(logged),
    [setLoggedInState]
  );

  return { isLoggedIn, setLoggedIn };
}

const User = createContainer(useUser);

export const Logged = createContainer(useLogin);

export default User;
