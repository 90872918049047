import React from "react";
import styled from "styled-components";

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  input,
  button {
    margin: 8px 0;
  }
`;

interface IProps {
  onSubmit?: () => void;
  className?: string;
}

const Form: React.FC<IProps> = ({ onSubmit, className, children }) => {
  const onFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (onSubmit) onSubmit();
  };

  return (
    <FormContainer onSubmit={onFormSubmit} className={className}>
      {children}
    </FormContainer>
  );
};

export default Form;
