import React from "react";
import MenuBook from "@material-ui/icons/MenuBook";
import styled from "styled-components";
import Colors from "../styles/colors";
import { MenuItem } from "../utils/routes";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

const Container = styled.div`
  & > .link {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 3rem;
    margin: 10px;
    text-decoration: none;
    color: ${Colors.White};
    text-transform: uppercase;
    border: 1px solid ${Colors.MediumGrey};
    background-color: ${Colors.DarkGrey};
    box-shadow: 0 2px 3px -1px ${Colors.Black};
    border-radius: 4px;

    & > .icon {
      font-size: 50px;
      margin-bottom: 10px;
    }

    &:hover, &:focus {
      color: ${Colors.MediumGreen};
      background-color: ${Colors.GreyBlue};
      border-color: ${Colors.MediumGreen};
    }
  }
`;

const Notifications = styled.div`
  position: absolute;
  top: -10px;
  right: -10px;
  width: 20px;
  height: 20px;
`;

interface IProps {
  menuItem: MenuItem;
  notifications?: number;
  className?: string;
}

const LinkCard: React.FC<IProps> = ({ menuItem, notifications, className }) => {
  const { link, icon: Icon = MenuBook, title } = menuItem;

  return (
    <Container className={className}>
      <Link to={link} className="link">
        {notifications && <Notifications>{notifications}</Notifications>}
        <Icon className="icon" />
        <FormattedMessage id={title} />
      </Link>
    </Container>
  );
};

export default LinkCard;
