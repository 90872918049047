import React from "react";
import styled from "styled-components";
import { MenuItems } from "../../utils/routes";
import LinkCard from "../../components/LinkCard";
import { breakPoints } from "../../styles/responsive";

const Container = styled.div`
  display: grid;
  grid-template-columns: 100%;
  width: 100%;

  @media (min-width: ${breakPoints.small}) {
    grid-template-columns: 50% 50%;
  }
  @media (min-width: ${breakPoints.medium}) {
    grid-template-columns: calc(100% / 3) calc(100% / 3) calc(100% / 3);
  }
`;

const Home: React.FC = () => {
  return (
    <Container>
      {MenuItems.map(mi => (
        <LinkCard menuItem={mi} key={mi.title} className="menuItem" />
      ))}
    </Container>
  );
};

export default Home;
